import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="hero3">
      <div className="content">
        <h1>
          <span role="img" aria-label="ghost">
            👻
          </span>{" "}
          PAGE NOT FOUND
        </h1>
        <p>Error 404</p>
        {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
        <p>This page does not exist.</p>
        {/* <p>This page does not exist... the sadness.</p> */}
        {/* <p>This is not the page you're looking for.</p> */}

        <p>
          <button onClick={e => window.history.back()}>
            <i className="material-icons">arrow_back</i> Back
          </button>
          <Link to="/">
            <button>
              <i className="material-icons">home</i> Home
            </button>
          </Link>
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
